import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Layout } from 'component/common/Layout/Layout';
import { Tooltip } from '@mui/material';
import { fetchFSAZones } from 'features/fsaZone/fsaZoneAction'
import DataTable from 'component/dataTable/dataTable'
import { Loader } from 'component/common/Loader/Loader'
import { TableHeader } from 'component/dataTable/tableHeader';
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { getDateWithTimeStamp } from 'utils/common'
import { resetState } from 'features/fsaZone/fsaZoneSlice'
import { PrimaryButton } from 'component/common/Button/Button'
import { AddFSAZone } from './AddFSAZone';
import '../userManagement/userAccount/UserAccount.scss'

const rowKey = "id"

export const FSAZone = () => {
    const dispatch = useDispatch();

    const { fsaZones, loading, success, error } = useSelector(
        (state) => state?.fsaZones
    )
    const { user } = useSelector(
        (state) => state?.auth
    )
    const [sorteData, setSortedData] = useState(fsaZones);
    const [updatedColumns, setUpdatedColumns] = useState([]);
    const [modalProps, setModalProps] = useState(null)
    const [addModelOpen, setAddModelOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState(null)

    useEffect(() => {
        dispatch(fetchFSAZones())
        return () => {
            dispatch(resetState())
        };
    }, [])

    useEffect(() => {
        if (error) {
            setModalProps(
                {
                    title: 'Error Occured!',
                    message: error,
                    open: true,
                    type: 'error'
                }
            )

        }
    }, [error]);

    useEffect(() => {
        if (success) {
            setModalProps(
                {
                    title: 'Success',
                    message: 'FSA Zones Updated Sussessfully!',
                    open: true,
                    type: 'success'
                }
            )
            const redirectTimer = setTimeout(() => {
                dispatch(resetState())
                setModalProps(null)
                setAddModelOpen(false)    
                dispatch(fetchFSAZones())
            }, 2000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [success])

    const handleAddClick = (item) => {
        setSelectedItem(item)
        setAddModelOpen(!addModelOpen)    
    }

    const columns = [
        {
            accessor: "fsaZoneId",
            title: "Id",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.fsaZoneId || '--',
            render: (item) => item?.fsaZoneId || '--'
        },
        {
            accessor: "fsaZoneName",
            title: "Zone Name",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.fsaZoneName || '--',
            render: (item) => item?.fsaZoneName || '--'
        },
        {
            accessor: "dcName",
            title: "DC Name",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.dcName || '--',
            render: (item) => item?.dcName || '--'
        },
        {
            accessor: "totalAddressLimit",
            title: "Total Address Limit",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.totalAddressLimit || '--',
            render: (item) => item?.totalAddressLimit || '--'
        },
        {
            accessor: "totalParcelLimit",
            title: "Max Parcel Count",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.totalParcelLimit || '--',
            render: (item) => item?.totalParcelLimit || '--'
        },
        {
            accessor: 'priority',
            title: "Priority",
            width: "2rem",
            Cell: ({ cell: { row: { original } } }) => original?.priority || '--',
            render: (item) => item?.priority || '--'
        },
        {
            accessor: "countOfFsaSubZone",
            title: "Total Sub Zones",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => original?.countOfFsaSubZone,
            render: (item) => item?.countOfFsaSubZone
        },
        {
            accessor: 'createDate',
            title: "Created Date",
            width: "3rem",
            Cell: ({ cell: { row: { original } } }) => getDateWithTimeStamp(original?.createDate, user),
            render: (item) => item?.createDate ? getDateWithTimeStamp(item?.createDate, user) : '--'
        },
        {
            accessor: 'action',
            title: "Action",
            width: "1rem",
            Cell: ({ cell: { row: { original } } }) => <div className='action' >
                <Tooltip title="Edit"><img src="/static/images/edit-icon.svg" alt='edit' onClick={() => handleAddClick(original)} /></Tooltip>
            </div>
        }
    ]

    return (
        <Layout headerTitle={'FSA Zones'} showBackArrow={false} action={<div><PrimaryButton type="button" variant='primary' label={'Add FSA Zone'} height={'30px'} onClick={() => handleAddClick(null)} /></div>}
        >
            <div className='container'>
                <TableHeader columns={updatedColumns} data={sorteData} exportFileName='Totes' />
                <div className='content'>
                    <DataTable columns={columns} data={fsaZones || []} isCollpsable={false} onSortChange={setSortedData} uniqueKey={rowKey} setUpdatedColumns={setUpdatedColumns} showPagination={false} rowKey={rowKey} />
                </div>
            </div>
            {addModelOpen ? <AddFSAZone isModelOpen={addModelOpen} handleClose={handleAddClick} fsaZone={selectedItem} /> : null}
            {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : null}
            {loading ? <Loader isLoading={loading} /> : null}
        </Layout>
    )
}