import React from 'react';
import styled from 'styled-components';
import Select from 'react-select';
import { auto } from '@popperjs/core';
import StarIcon from '@mui/icons-material/Star';

export const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props?.width || '14rem'};
`


export const StyledLabel = styled.label.withConfig({
  shouldForwardProp: (prop) =>
    !['lablecolor'].includes(prop),
})`
   display: block;
  letter-spacing: 0px;
  color: ${props => props?.lablecolor || '#1A1A1A'};
  font-size: 16px;
  font-family: "degular", sans-serif;
  font-weight: 400;
`;

const customStyles = {
  control: (provided) => ({
    ...provided,
    // height: '30px',
    border: '1px solid #E4E4E4',
    borderRadius: '7px',
    cursor: 'pointer',
    overflow: auto,
    maxHeight: 100,
    fontSize: 14,
    fontFamily: 'Degular, sans-serif',

  }),
  option: (provided) => ({
    ...provided,
    color: 'black', // Set the color to black
  }),
  placeholder: (provided) => ({
    ...provided,
    color: '#999',
    fontSize: '14px',
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 999,
    maxHeight: '200px', // Set the maximum height for the dropdown menu
  })
};

export const ErrorMessage = styled.span`
  color: red;
  font-size: 12px;
  margin-top: 5px;
  width: ${props => props?.width || '14rem'};
`;


const StyledSelect = styled(Select)`
  width: ${(props) => (props?.width ? props.width : '14rem')};
  &::placeholder {
    color: #999; /* Change the placeholder text color */
    font-size: 14px; /* Change the placeholder font size */
  }
`;

const CustomSelect = ({ name, value, isClearable = true, menuPosition = 'auto', menuPlacement = 'auto', isMulti, ...rest }) => {
  const handleChange = (selectedOption) => {
    if (rest.onChange) {
      rest.onChange({ target: { name, value: selectedOption } });
    }
  };

  return <StyledSelect {...rest} value={value} onChange={handleChange}
    className="single-select"
    classNamePrefix="react-select"
    menuPosition={menuPosition}
    menuPlacement={menuPlacement}
    maxMenuHeight={200}
    isClearable={isClearable}
    closeMenuOnSelect={isMulti ? false : true}
    isMulti={isMulti}
  />;
};



export const SelectField = ({ label, dataTestId, name, options, isMultiselect, lableColor, error, handleFieldChange, required, isClearable, onFocus, onBlur, menuPosition, isDisabled, ...rest }) => {
  const customGetOptionLabel = (option) => (
    <div data-testid={`option-${option.label}`}>{option.label}</div>
  );
  return (

    <FieldWrapper data-testid={`select-${name}`} {...rest}>
      <StyledLabel lablecolor={lableColor} data-testid={dataTestId ? `label-${dataTestId}` : `label-${name}`}  >{label}
        {required && <StarIcon style={{ color: 'red', fontSize: '8px', verticalAlign: 'middle', marginLeft: '5px' }} />}
      </StyledLabel>
      <CustomSelect data-testid={dataTestId ? dataTestId : `name-${name}`} name={name} {...rest} onFocus={onFocus} onBlur={onBlur} onChange={handleFieldChange}
        styles={customStyles}
        isMulti={isMultiselect}
        isClearable={isClearable}
        menuPosition={menuPosition}
        isDisabled={isDisabled}
        filterOption={(option, inputValue) =>
          option?.data?.label?.toLowerCase().includes(inputValue?.toLowerCase())
        }
        options={options}
        getOptionLabel={customGetOptionLabel}
        closeMenuOnScroll={(e) => {
          if (e?.target instanceof Element) {
            if (!e?.target.closest('.react-select__menu')) {
              return true; // Close the menu
            }
          }
          return false; // Keep the menu open
        }}

      />
      {error && <ErrorMessage width={rest?.width}>{error}</ErrorMessage>}
    </FieldWrapper>
  );
};