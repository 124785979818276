import { environment } from "../environments/environment.jsx";
import api from './api';
import { getHeader } from './authUtils.js'

const apiEndpoint = environment.webservices.endpoint;

export class PackageServices {
    getAllPackages(queryParams) {
        const endpoint = `${apiEndpoint}/api/package-details`
        return api.get(endpoint, {
            headers: getHeader(),
            params: queryParams
        });
    }

    getPackageByTrackingNumber(formData) {
        const endpoint = `${apiEndpoint}/api/package-details`;
        return api.get(endpoint, {
            headers: getHeader(),
            params: formData 
        });
    }
    updatePackageStatus(trackingnumber, status) {
        const endpoint = `${apiEndpoint}/api/package-details/status/${trackingnumber}/${status}`;
        return api.put(endpoint, null, {
            headers: getHeader(),
        });
    }
    updatePackage(formData) {
        const { trackingNumber, ...rest } = formData
        const endpoint = `${apiEndpoint}/api/package-details/update/${trackingNumber}`;
        return api.put(endpoint, rest, {
            headers: getHeader(),
        });
    }
    getAssetImages(formData) {
        const endpoint = `${apiEndpoint}/api/downloadFromS3?trackingNumber=${formData}`;
        return api.get(endpoint, {
            headers: getHeader(),
        });
    }
    getReturnPackages(queryParams) {
        const endpoint = `${apiEndpoint}/api/package-details/returned`
        return api.get(endpoint, {
            headers: getHeader(),
            params: queryParams
        });
    }
    updateNotificationStatus(formData) {
        console.log("formdata>>", formData);
        const endpoint = `${apiEndpoint}/api/consent/update`
         return api.put(endpoint, formData,
            {
             headers: getHeader(),
         });
     }

     updateBusinessHours(formData) {
        const { trackingNumber, ...rest } = formData
        const endpoint = `${apiEndpoint}/api/package-details/${trackingNumber}`
         return api.put(endpoint, rest,
            {
             headers: getHeader(),
         });
     }

     removeSignatureAttribute(formData) {
        const endpoint = `${apiEndpoint}/api/package-details/signature-only/${formData?.trackingNumber}`;
        return api.put(endpoint, false, {
            headers: getHeader(),
        });
    }
     

    updateReturnPackage(formData) {
        const endpoint = `${apiEndpoint}/api/package-details/update/returns/status`;
        return api.put(endpoint, formData, {
            headers: getHeader(),
        });
    }
    reclaimTotes(formData) {
        const endpoint = `${apiEndpoint}/api/totes/updateStatus`;
        return api.put(endpoint, formData, {
            headers: getHeader(),
        });
    }
    scanPackage(trackingID) {
        const endpoint = `${apiEndpoint}/api/package-details/scan-returnPackage?trackingNumber=${trackingID}`;
        return api.put(endpoint, null,{
            headers: getHeader(),
        });
    }
    getStreetPerfectDetails = (formData) => {
        const endpoint = `${apiEndpoint}/api/streetperfect/validateaddress`;
        return api.post(endpoint, formData, {
            headers: getHeader(),
        });
    }

    markHighPriorityAndSLA(formData) {
        const { type, value, trackingNumber } = formData
        const endpoint = `${apiEndpoint}/api/package-details/mark/slahighpriority?type=${type}&value=${value}&trackingNumber=${trackingNumber}`;
        return api.put(endpoint, null, {
            headers: getHeader(),

        });
    }

    markOnHold(formData) {
        const endpoint = `${apiEndpoint}/api/change-hold-status?trackingNumber=${formData?.trackingNumber}&action=${formData?.action}`;
        return api.post(endpoint, null, {
            headers: getHeader(),
        });
    }

    
}





