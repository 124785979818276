import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Layout } from 'component/common/Layout/Layout';
import Popover from '@mui/material/Popover';
import { fetchManifests } from 'features/manifestManagement/manifestManagementAction'
import { useNavigate } from "react-router-dom";
import { Paper, List, ListItem, ListItemText, Divider, Link } from '@mui/material';
import DataTable from 'component/dataTable/dataTable'
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { Loader } from 'component/common/Loader/Loader'
import { PrimaryButton } from 'component/common/Button/Button'
import { TableHeader } from 'component/dataTable/tableHeader';
import { TableFilter } from 'component/dataTable/tableFilter';
import { covertToString, getCurrentDateForFilter, convertDateRangeToUTC, getDateWithTimeStamp, getUserDCOption, getAPIFilterAsPerRole, checkPermission } from 'utils/common'
import { getPackagesStatusCount, getPhysicalArrivalStatus, getRoutingStatus, getStatusStyle} from 'utils/manifestHelper'
import { setPackageFilter } from 'features/packages/packagesSlice'
import { fetchTenants } from 'features/userManagement/userManagementAction'
import { getDataCentersList, getClientList } from 'utils/userAccountHelper'
import { validateForm, validateStartAndEndDate } from 'utils/formValidator'
import { manifestStatus } from 'constants/manifestStatusConstant';
import { fetchClients } from 'features/clientManagement/clientManagementAction'
import { AddManifest } from './AddManifest';
import ManifestUploadErrorDialog from './ManifestUploadErrorDialog'
import { urls } from 'utils/urls'
import useFieldChange from 'hooks/useFieldChange';
import '../userAccount/UserAccount.scss'

const date = getCurrentDateForFilter()

const formInitialState = {
    startDate: date,
    endDate: date,
    shipperName: '',
    dcName: '',
    businessName: '',
    manifestStatus: ''
}

export const Manifest = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { manifests, error, loading, manifestCount, addManifestSuccess } = useSelector(
        (state) => state?.manifestManagement
    )
    const [page, setPage] = useState(0);
    const { dataCenters } = useSelector(
        (state) => state?.userManagement
    )
    const { isMobile } = useSelector(
        (state) => state?.viewport
    )
    const { clients } = useSelector(
        (state) => state?.clientManagement
    )
    const { userProfile, user } = useSelector(
        (state) => state?.auth
    )
    const filter = getAPIFilterAsPerRole(user, userProfile)
    const isBusinessNameDisabled = filter?.businessName ? true : false
    const hasEditPermission = checkPermission(user, 'ORDER MANAGEMENT')

    const [modalProps, setModalProps] = useState(null)
    const [anchorEl, setAnchorEl] = useState(null);
    const [popOverData, setPopOverData] = useState({})
    const [manifestId, setManifestId] = useState(null)
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});
    const [applyFilter, setApplyFilter] = useState(false);
    const [sorteData, setSortedData] = useState(manifests);
    const [updatedColumns, setUpdatedColumns] = useState([]);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [addManifestModalOpen, setAddManifestModalOpen] = useState(false)
    const [errorDetails, setErrorDetails] = useState(null)
    const [errorPopup, setErrorPopup] = useState(false)
    const [showLink, setShowLink] = useState(false)

    const fetchData = () => {
        if (!user?.authorities?.includes('ROLE_CUSTOMER') || userProfile) {
            const data = {}
            const { startDate, endDate } = formValues
            const dateFilter = convertDateRangeToUTC(startDate, endDate, "YYYY-MM-DD")

            data['dcName'] = getUserDCOption(user)?.value
            
            if (formValues?.shipperName) {
                data['shipperName'] = formValues?.shipperName?.trim()
            }
            if (formValues?.businessName || filter?.businessName) {
                data['bussinessName'] = formValues?.businessName?.value || filter?.businessName
            }
            if (formValues?.manifestStatus) {
                data['manifestStatus'] = formValues?.manifestStatus?.value
            }
            dispatch(fetchManifests({
                page: page,
                size: rowsPerPage,
                ...data,
                ...dateFilter
            }))
        }
    }

    useEffect(() => {
        //Set business name to filter for filtering client data
        if (filter?.businessName) {
            setFormValues({
                businessName: { label: filter?.businessName, value: filter?.businessName }
            })
        }
    }, [filter?.businessName])

    useEffect(() => {
        fetchData()
    }, [page, applyFilter, rowsPerPage, userProfile])

    useEffect(() => {
        if (addManifestSuccess) {
            fetchData()
        }
    }, [addManifestSuccess])

    useEffect(() => {
        dispatch(fetchTenants())
        dispatch(fetchClients({
            page: 0,
            size: 500,
            dcName: getUserDCOption(user)?.value
        }))
    }, [])

    useEffect(() => {
        if (error) {
            //Check if error is array or not
            try {
                const errorArray = JSON.parse(error);
                setErrorPopup(true)
                // If parsing succeeds, format it into a table
                setErrorDetails(errorArray)

            } catch (e) {
                // If parsing fails, display the error message as is
                setModalProps({
                    title: 'Error Occurred!',
                    message: error,
                    open: true,
                    type: 'error'
                });
            }
        } else {
            // No error, close modal or reset modal props
            setModalProps({
                title: '',
                message: '',
                open: false,
                type: ''
            });
        }
    }, [error]);

    const handleAddManifest = () => {
        setAddManifestModalOpen(!addManifestModalOpen)
    }

    const handleColumnClick = (event, manifestId, data, showLink) => {
        setManifestId(manifestId)
        setPopOverData(data)
        setShowLink(showLink)
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };


    const handlePageChange = (event, page) => {
        setPage(page)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target?.value, 10));
        setPage(0);
    };

    const handleResetFilter = () => {
        setFormValues({
            startDate: '',
            endDate: '',
            shipperName: '',
            dcName: '',
            manifestStatus: '',
            businessName: isBusinessNameDisabled ? { label: filter?.businessName, value: filter?.businessName } : ''
        })
        setFormErrors({})
    }

    const handleNavigation = (params) => {
        dispatch(setPackageFilter(params))
        navigate(urls?.PARCEL_WIDGET_URL)
    }

    const handlePopUpClose = () => {
        setErrorDetails(null)
        setErrorPopup(false)
    }

    const handleApplyFilterClick = () => {
        const notRequiredFields = ['startDate', 'endDate', 'shipperName', 'dcName', 'businessName', 'manifestStatus']
        const errors = validateForm(formValues, notRequiredFields);
        const dateErrors = validateStartAndEndDate(formValues?.startDate, formValues?.endDate);
        if (Object.keys(errors).length || Object.keys(dateErrors).length) {
            setFormErrors(Object.keys(errors).length ? errors : dateErrors)
        }
        else {
            setFormErrors({})
            setApplyFilter(!applyFilter)
            setPage(0)
        }
    }

    const open = Boolean(anchorEl);

    const columns = [
        {
            accessor: "manifestId",
            title: "ID",
            width: "5rem",
            Cell: ({ cell: { value } }) => <Link id='manifestId' style={{ cursor: 'pointer' }} sx={{ textDecoration: 'none' }} onClick={() => handleNavigation({
                manifestId: value
            })}>{value}</Link> || '--',
            render: (item) => item?.manifestId || '--',
        },
        {
            accessor: "bussinessName",
            title: "Business Name",
            width: "5rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.bussinessName || '--'
        },
        {
            accessor: "shipperName",
            title: "Shipper Name",
            width: "5rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.shipperName || '--'
        },
        {
            accessor: 'arrivedInDc',
            title: "DC",
            width: "5rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.arrivedInDc || '--'
        },
        {
            accessor: 'pysicalArrivalStatus',
            title: "Physical Arrival Status",
            width: "10rem",
            Cell: ({ cell: { row: { original } } }) => getPhysicalArrivalStatus(parseInt(original?.scannedPackages), parseInt(original?.totalPackageCount)),
            render: (item) => getPhysicalArrivalStatus(parseInt(item?.scannedPackages), parseInt(item?.totalPackageCount)),
            isSortable: false
        },
        {
            accessor: 'manifestStatus',
            title: "Status",
            width: "10rem",
            Cell: ({ cell: { value } }) => value ? covertToString(value) : '--',
            render: (item) => item?.manifestStatus ? covertToString(item?.manifestStatus) : '--'
        },
        {
            accessor: "totalPackageCount",
            title: "Total Parcels",
            width: "5rem",
            Cell: ({ cell: { value } }) => value || '--',
            render: (item) => item?.totalPackageCount || '--'
        },
        {
            accessor: "packageInError",
            title: "Parcels With Error",
            width: "5rem",
            Cell: ({ cell: { value } }) => value,
            render: (item) => item?.packageInError
        },
        {
            accessor: "routingStatus",
            title: "Routing Status",
            width: "10rem",
            Cell: ({ cell: { row: { original } } }) => {
                const status = getRoutingStatus(parseInt(original?.totalPackagesRouted), parseInt(original?.totalPackageCount));
                return (<span style={getStatusStyle(status)}>{status}</span>)
            },
            render: (item) => getRoutingStatus(parseInt(item?.totalPackagesRouted), parseInt(item?.totalPackageCount)),
            isSortable: false
        },
        {
            accessor: "deliveryStatus",
            title: "Delivery Status",
            width: "5rem",
            Cell: ({ cell: { row: { original } } }) => original?.packageStatusAndNumbers && Object.keys(original?.packageStatusAndNumbers)?.length ? <Link id='deliveryStatus' style={{ cursor: 'pointer' }} sx={{ textDecoration: 'none' }} onClick={(event) => handleColumnClick(event, original?.manifestId, original?.packageStatusAndNumbers, true)}>{getPackagesStatusCount(original?.packageStatusAndNumbers)}</Link> : '--',
            render: (item) => item?.packageStatusAndNumbers && Object.keys(item?.packageStatusAndNumbers)?.length ? getPackagesStatusCount(item?.packageStatusAndNumbers) : '--',
            isSortable: false
        },
        {
            accessor: "orderProcessingPackageStatusAndNumber",
            title: "Order Procesing",
            width: "5rem",
            Cell: ({ cell: { row: { original } } }) => original?.orderProcessingPackageStatusAndNumber && Object.keys(original?.orderProcessingPackageStatusAndNumber)?.length ? <Link id='orderProcessing' style={{ cursor: 'pointer' }} sx={{ textDecoration: 'none' }} onClick={(event) => handleColumnClick(event, original?.manifestId, original?.orderProcessingPackageStatusAndNumber, false)}>{original?.totalPackageCount}</Link> : '--',
            render: (item) => item?.totalPackageCount,
        },
        {
            accessor: "manifestFileName",
            title: "Source Name",
            width: "5rem",
            Cell: ({ cell: { value } }) => value,
            render: (item) => item?.manifestFileName,
        },
        {
            accessor: 'submissionDate',
            title: "Upload Date",
            width: "10rem",
            Cell: ({ cell: { value } }) => value ? getDateWithTimeStamp(value, user) : '--',
            render: (item) => item?.submissionDate ? getDateWithTimeStamp(item?.submissionDate, user) : '--'
        }]


    const tableFilterProps = {
        fields: [{
            label: 'Shipper Name',
            value: formValues?.shipperName,
            handleFieldChange: handleFieldChange,
            type: 'text',
            width: isMobile ? '9rem' : '11rem',
            name: 'shipperName',
            error: formError?.shipperName,
            placeholder: 'Shipper Name'
        },
        {
            label: 'Business Name',
            value: formValues?.businessName,
            handleFieldChange: handleFieldChange,
            type: 'select',
            width: isMobile ? '9rem' : '11rem',
            options: getClientList(clients),
            name: 'businessName',
            isDisabled: isBusinessNameDisabled
        },
        {
            label: 'DC',
            value: getUserDCOption(user),
            handleFieldChange: handleFieldChange,
            type: 'select',
            width: isMobile ? '9rem' : '11rem',
            options: getDataCentersList(dataCenters),
            name: 'dcName',
            isDisabled: true
        },
        {
            label: 'Manifest Status',
            value: formValues?.manifestStatus,
            handleFieldChange: handleFieldChange,
            type: 'select',
            width: isMobile ? '9rem' : '11rem',
            options: manifestStatus,
            name: 'manifestStatus'
        },
        {
            label: 'Upload Period Start',
            value: formValues?.startDate,
            handleFieldChange: handleFieldChange,
            type: 'date',
            width: isMobile ? '9rem' : '11rem',
            name: 'startDate',
            placeholder: 'DD-MM-YYYY',
            error: formError?.startDate,
            max: new Date()?.toISOString()?.split('T')?.[0]
        },
        {
            label: 'Upload Period End',
            value: formValues?.endDate,
            handleFieldChange: handleFieldChange,
            type: 'date',
            width: isMobile ? '9rem' : '11rem',
            name: 'endDate',
            placeholder: 'DD-MM-YYYY',
            error: formError?.endDate,
            max: new Date()?.toISOString()?.split('T')?.[0]
        }
        ],
        buttons: [
            {
                label: 'Apply',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'black',
                onClick: handleApplyFilterClick
            },
            {
                label: 'Reset',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'blue',
                onClick: handleResetFilter
            }
        ],
        margin: '0px',
        padding: '10px',
        borderRadius: '10px'
    }

    return (
        <Layout headerTitle={'Manifests'} backUrl='/' action={hasEditPermission ? <div><PrimaryButton type="button" variant='primary' label={'Add Manifest'} height={'30px'} onClick={handleAddManifest} /> </div> : null}
        >
                <div className='container'>
                    <TableHeader columns={updatedColumns} data={sorteData} exportFileName='Manifests' onRefreshClick={handleApplyFilterClick} />
                    <TableFilter {...tableFilterProps} />
                    <div className='content'>
                        <DataTable columns={columns} data={manifests || []} isCollpsable={false} showPagination={manifests?.length ? true : false} page={page} totalRowsCount={manifestCount} onPageChange={handlePageChange} onSortChange={setSortedData} uniqueKey={'manifestId'} setUpdatedColumns={setUpdatedColumns} handleChangeRowsPerPage={handleChangeRowsPerPage} rowsPerPage={rowsPerPage} />
                        <Popover
                            open={open}
                            anchorEl={anchorEl}
                            onClose={handlePopoverClose}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <Paper sx={{
                                maxWidth: '100%'
                            }}>
                                <List >
                                    {
                                        Object.entries(popOverData)?.map(([key, value]) => {
                                            return (
                                                <>
                                                    <ListItem key={key} style={{ cursor: 'pointer' }} onClick={() => showLink ? handleNavigation(
                                                        {
                                                            manifestId: manifestId,
                                                            packageStatus: key
                                                        }
                                                    ) : null}>
                                                        <Link style={{ cursor: 'pointer' }}><ListItemText
                                                            id={key}
                                                            primary={`${covertToString(key)} : ${value}`}
                                                        />
                                                        </Link>
                                                    </ListItem>
                                                    <Divider />
                                                </>
                                            )
                                        })
                                    }
                                </List>
                            </Paper>
                        </Popover>
                        {addManifestModalOpen ? <AddManifest isModelOpen={addManifestModalOpen} handleClose={handleAddManifest} /> : null}
                    </div>
                </div>
            {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : null}
            {loading ? <Loader isLoading={loading} /> : null}
            {errorPopup ? <ManifestUploadErrorDialog errorDetails={errorDetails} isOpen={errorPopup} handlePopUpClose={handlePopUpClose} /> : null}
        </Layout>
    )
}