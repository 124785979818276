import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link } from '@mui/material';
import { Layout } from 'component/common/Layout/Layout';
import { fetchPackages, updateReturnPackage } from 'features/packages/packagesAction'
import DataTable from 'component/dataTable/dataTable'
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { Loader } from 'component/common/Loader/Loader'
import { TableHeader } from 'component/dataTable/tableHeader';
import { getConstantValue, getDate, getUserDCOption } from 'utils/common'
import { iconStyle } from 'utils/common'
import { packageStatus, packagesRTDTEnum } from 'constants/packageStatus'
import 'component/userManagement/userAccount/UserAccount.scss'
import { EditPackageAddress } from './EditPackageAddress'
import {  clearSelectedPackages, resetSuccessState, resteStreetPerfectDetails, togglePackageSelection, resetError } from 'features/packages/packagesSlice'
import InfoIcon from '@mui/icons-material/Info';
import { setAuditData } from 'features/audit/auditSlice'
import { Audit } from 'component/audit/audit'
import { Tooltip } from '@mui/material';
import { getAPIFilterAsPerRole, checkPermission } from 'utils/common.js'
import { RescheduleDelivery } from 'component/returnDashboard/ReschedulePackage'
import { SpecialInstrucionsCellRenderer } from 'component/packages/SpecialInstructionsCellRenderer'
import { canViewInternalComments } from 'utils/packageHelper'
import { urls } from 'utils/urls'
import { TableFilter } from 'component/dataTable/tableFilter';
import { getClientList } from 'utils/userAccountHelper';
import useFieldChange from 'hooks/useFieldChange';
import { handleApplyFilter } from 'utils/common';
import { fetchClients } from 'features/clientManagement/clientManagementAction';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { rtsAllowedStatuses } from 'constants/packageStatus';
import { TabWrapper, Tab } from 'component/common/Tab/Tab';
import { TextField } from "component/common/Input/TextField";
import { PrimaryButton } from 'component/common/Button/Button';
import Billing from 'component/common/Billing/Billing';

const RTSTabEnum = {
    RTERMINAL_RTS_T1: 'RTERMINAL_RTS_T1',
    RTERMINAL_RTS_T2: 'RTERMINAL_RTS_T2'
}   
const formInitialState = {
    trackingNumber: '',
    businessName: ''   
}


export const ReturnToSender = () => {
    const dispatch = useDispatch();
    const { packages, error, loading, packageCount, updatePackageSuccess, streetPerfectDetails } = useSelector(
        (state) => state?.packages
    )
    const { userProfile, user } = useSelector(
        (state) => state?.auth
    )
    const { sla, highPriority } = useSelector(
        (state) => state?.deliveryStatusManagement
    )
    const { isMobile } = useSelector(
        (state) => state?.viewport
    )
    const { clients } = useSelector(
        (state) => state?.clientManagement
    )

    const filter = getAPIFilterAsPerRole(user, userProfile)
    const isBusinessNameDisabled = filter?.businessName ? true : false
    const [modalProps, setModalProps] = useState(null)
    const [page, setPage] = useState(0);
    const [sorteData, setSortedData] = useState(packages);
    const [updatedColumns, setUpdatedColumns] = useState([]);
    const [selectedPackage, setSelectcedPackage] = useState(null);
    const [showAddressUpdateModal, setShowAddressUpdateModal] = useState(false)
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [showStatusUpdateModal, setShowStatusUpdateModal] = useState(false)
    const hasEditPermission = checkPermission(user, 'RETURN_TO_SENDER')
    const hasInternalCommentsAccess = canViewInternalComments(user)
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [formError, setFormErrors] = useState({});
    const [applyFilter, setApplyFilter] = useState(false);
    const [selectedTab, setSelectedTab] = useState(RTSTabEnum?.RTERMINAL_RTS_T1);
    const selectedTabRef = useRef();
    const [trackingID, setTrackingID] = useState("");
    const [showBill, setShowBill] = useState(false)

    const fetchPackagesData = () => {
        const filter = getAPIFilterAsPerRole(user, userProfile);
        const baseFilter = {
            page: page,
            size: rowsPerPage,
            ...filter,
            dcName: getUserDCOption(user)?.value
        }

        const additionalFilter = {} 
        additionalFilter['widget'] = selectedTab === RTSTabEnum?.RTERMINAL_RTS_T1
            ? 'RETURN_TO_SENDER_T1': 'RETURN_TO_SENDER_T2' 

        if(formValues?.trackingNumber){
            baseFilter['trackingNumber'] = formValues?.trackingNumber
        }

        if (formValues?.businessName) {
            baseFilter['businessName'] = formValues?.businessName?.value;
        }

        dispatch(fetchPackages({
            ...baseFilter,
            ...additionalFilter,
        }));
    };

    useEffect(() => {
        dispatch(fetchClients({
            page: 0,
            size: 500,
            dcName: getUserDCOption(user)?.value
        }))
        return () => {
            dispatch(resetError())
        };
    }, [])

    useEffect(() => {
        return () => {
            dispatch(clearSelectedPackages());
        };
    }, [dispatch]);

    useEffect(() => {
        fetchPackagesData()
    }, [page, rowsPerPage, highPriority, sla, applyFilter, selectedTab])

    useEffect(() => {
        if (error) {
            setModalProps(
                {
                    title: 'Error Occured!',
                    message: error,
                    open: true,
                    type: error
                }
            )

        }
    }, [error]);

    useEffect(() => {
        if (updatePackageSuccess) {
            setModalProps(
                {
                    title: 'Success',
                    message: 'Parcel Updated Successfully!',
                    open: true,
                    type: 'success'
                }
            )
            const redirectTimer = setTimeout(() => {
                if (showAddressUpdateModal) {
                    handlePackageEditCick()
                }
                if (showStatusUpdateModal) {
                    handlePackageStatusClick()
                }
                dispatch(resetSuccessState())
                fetchPackagesData()
                setModalProps(null)
            }, 1000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [updatePackageSuccess]);

    useEffect(() => {
        setShowBill(false); 
      }, [formValues.businessName]);

    const handlePageChange = (event, page) => {
        setPage(page)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target?.value, 10));
        setPage(0);
    };

    const trackPackagestatus = (id) => {
        const url = `${urls?.PARCEL_TRACKING_WIDGET_URL}?tracker-id=${id}`;
        window.open(url, '_blank');
    }

    const handlePackageEditCick = (data) => {
        if (!showAddressUpdateModal) {
            setSelectcedPackage(data)
            setShowAddressUpdateModal(true)
        }
        else {
            setSelectcedPackage(null)
            setShowAddressUpdateModal(false)
        }
    }

    const handlePackageStatusClick = (data) => {
            dispatch(togglePackageSelection({packageIds : data?.trackingNumber}))
            setShowStatusUpdateModal(!showStatusUpdateModal);            
    }

    const handleApplyFilterClick = () => {
        setShowBill(true)
        handleApplyFilter(formValues, formInitialState, setFormErrors, setApplyFilter, setPage, applyFilter);
    }

    const handleResetFilter = () => {
        setFormValues({
            businessName: isBusinessNameDisabled ? { label: filter?.businessName, value: filter?.businessName } : '',
            trackingNumber: ''
        })
        setFormErrors({})
    }

    const tabs = [
        {
            value:RTSTabEnum?.RTERMINAL_RTS_T1,
            label: 'Staged for RTS'
        },
        {
            value: RTSTabEnum?.RTERMINAL_RTS_T2,
            label: 'Returned to Sender' 
        }
    ]

    const handleTabChange = (event, value) => {
        selectedTabRef.current = value
        setSelectedTab(value)
    }

    const handleTrackingIDChange = (event) => {
        setTrackingID(event.target.value);
      };

      const handleKeyDown = (event) => {
        if (event.key === "Enter") {
          handleSubmitClick();
        }
      };

    const trackingIDField = {
        value: trackingID,
        handleFieldChange: handleTrackingIDChange,
        type: "text",
        width: "12rem",
        name: "trackingID",
        error: "",
        placeholder: "Tracking ID",
        onKeyDown: handleKeyDown,
      };


    const tableFilterProps = {
        fields: [
            {
                label: 'Tracking Number',
                value: formValues?.trackingNumber,
                handleFieldChange: handleFieldChange,
                type: 'text',
                width: isMobile ? '9rem' : '12rem',
                name: 'trackingNumber',
                error: formError?.trackingNumber,
                placeholder: 'Tracking Number'
            },
            {
                label: 'Business Name',
                value: formValues?.businessName,
                handleFieldChange: handleFieldChange,
                type: 'select',
                width: isMobile ? '9rem' : '11rem',
                options: getClientList(clients),
                name: 'businessName',
                error: formError?.businessName,
                isDisabled: isBusinessNameDisabled
            },
        ],
        buttons: [
            {
                label: 'Apply',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'black',
                onClick: handleApplyFilterClick
            },
            {
                label: 'Reset',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'blue',
                onClick: handleResetFilter
            }
        ],
        margin: '0px',
        padding: '10px',
        borderRadius: '10px',
    }

    const columns = [{
        accessor: "packageDetailsId",
        title: "ID",
        width: "2rem",
        Cell: ({ cell: { value } }) => value || '--',
        render: (item) => item?.packageDetailsId
    },
    {
        accessor: "trackingNumber",
        title: "Tracking Number",
        width: "3rem",
        Cell: ({ cell: { value } }) => (
            <Link id='trackingNumber' sx={{ textDecoration: 'none' }} style={iconStyle}
                onClick={(e) => 
                {trackPackagestatus(value)}}
            >
                {value}
            </Link>
        ) || '--',
        render: (item) => item?.trackingNumber || '--'
    },
    {
        accessor: "consignee",
        title: "Consignee",
        width: "4rem",
        Cell: ({ cell: { value } }) => value || '--',
        render: (item) => item?.consignee || '--'
    },
    {
        accessor: "bussinessName",
        title: "Client",
        width: "4rem",
        Cell: ({ cell: { row: { original } } }) => original?.bussinessName || '--',
        render: (item) => item?.bussinessName || '--'
    },
    {
        accessor: 'packageStatus',
        title: "RTS Status",
        width: "5rem",
        Cell: ({ cell: { value } }) => value ? getConstantValue(packageStatus, value) : '--',
        render: (item) => item?.packageStatus ? getConstantValue(packageStatus, item?.packageStatus) : '--'
    }, 
    {
        accessor: 'latestUnsuccessfulStatus',
        title: "Latest unsuccessful status",
        width: "4rem",
        Cell: ({ cell: { value } }) => value ? getConstantValue(packageStatus, value) : '--',
        render: (item) => item?.latestUnsuccessfulStatus ? getConstantValue(packageStatus, item?.latestUnsuccessfulStatus) : '--'
    }, 
    {
        accessor: "addressOne",
        title: "Address Line 1",
        width: "6rem",
        Cell: ({ cell: { row: { original } } }) => original?.addressOne || '--',
        render: (item) => item?.addressOne || '--'
    },
    {
        accessor: "addressTwo",
        title: "Address Line 2",
        width: "6rem",
        Cell: ({ cell: { row: { original } } }) => original?.addressTwo || '--',
        render: (item) => item?.addressTwo || '--'
    },
    {
        accessor: "city",
        title: "City",
        width: "6rem",
        Cell: ({ cell: { value } }) => value || '--',
        render: (item) => item?.city || '--'
    },
    {
        accessor: "province",
        title: "Province",
        width: "6rem",
        Cell: ({ cell: { row: { original } } }) => original?.province || '--',
        render: (item) => item?.province || '--'
    },
    {
        accessor: "postalCode",
        title: "Postal Code",
        width: "4rem",
        Cell: ({ cell: { row: { original } } }) => original?.postalCode || '--',
        render: (item) => item?.postalCode || '--'
    },    
    {
        accessor: "shipperName",
        title: "Shipper",
        width: "4rem",
        Cell: ({ cell: { row: { original } } }) => original?.shipperName || '--',
        render: (item) => item?.shipperName || '--'
    }, 
    selectedTab === RTSTabEnum?.RTERMINAL_RTS_T1
    ? {
        accessor: "rtsT1Date",
        title: "Staged for RTS application date",
        width: "4rem",
        Cell: ({ cell: { value } }) => value ? getDate(value) : '--',
        render: (item) => item?.rtsT1Date ? getDate(item?.rtsT1Date) : '--'
    }
    : {
        accessor: "rtsT2Date",
        title: "Returned to sender application date",
        width: "4rem",
        Cell: ({ cell: { value } }) => value ? getDate(value) : '--',
        render: (item) => item?.rtsT2Date ? getDate(item?.rtsT2Date) : '--'
    },
    {
        accessor: "specialInstructions",
        title: "Special Instructions",
        width: "6rem",
        Cell: ({ cell: { row: { original } } }) => original?.specialInstructions ? <SpecialInstrucionsCellRenderer data={original} type={'specialInstructions'} /> :  '--',
        render: (item) => item?.specialInstructions || '--'
    },
    hasInternalCommentsAccess && {
        accessor: "internalComment",
        title: "Internal Comments",
        width: "5rem",
        Cell: ({ cell: { row: { original } } }) => original?.internalComment ? <SpecialInstrucionsCellRenderer data={original} type={'internalComments'} /> : '--',
        render: (item) => item?.internalComment || '--'
    },
    hasEditPermission && {
        accessor: 'action',
        title: "Action",
        isSortable: false,
        width: "4rem",
        Cell: ({ cell: { row: { original } } }) => <div className='action' >  <Tooltip title="Edit"><img src="static/images/edit-icon.svg" alt='edit' onClick={() => handlePackageEditCick(original)} /></Tooltip>
                {original?.packageStatus !== packagesRTDTEnum?.RTERMINAL_RTS_T2 && ( <Tooltip title="Update Status"><ChangeCircleIcon style={iconStyle} fontSize="small" onClick={() => handlePackageStatusClick(original)} /></Tooltip>)}
            <Tooltip title="Info"><InfoIcon style={iconStyle} fontSize="small" onClick={() => dispatch(setAuditData({ 'entityId': original?.trackingNumber, 'entityType': 'package-details' }))} /></Tooltip>
        </div>
    }
    ]?.filter(Boolean); // Filter out falsy values (null, undefined, false)

    const handleModelClose = () => {
        setModalProps(null)
        dispatch(resetError())
        if (streetPerfectDetails) {
            dispatch(resteStreetPerfectDetails())
        }
    }

    const handleSubmitClick = () => {
    const isTrackingIDValid = packages.some(pkg => pkg.trackingNumber === trackingID?.trim());
    if (!isTrackingIDValid) {
        setModalProps({
            title: 'Error Occurred!',
            message: `Tracking ID does not exist`,
            open: true,
            type: 'error'
        });
        setTrackingID('');
        return;
    }

    const data = {
        trackingNumbers: [trackingID?.trim()],
        returnStatus: 'RTERMINAL_RTS_T2'
    };
    if(formValues?.businessName){
        data.businessName = formValues?.businessName?.value?.trim()
    }
    setFormErrors({});
    dispatch(updateReturnPackage(data));
    setTrackingID('');
};


    const getClientIDByBusinessName = () => {
        const selectedClient = clients?.find(client => client.businessName === formValues?.businessName?.value);
        return selectedClient ? selectedClient.id : null;
    };

    
    return (
        <Layout headerTitle={'Return To Sender'} showBackArrow={false} action={selectedTab === RTSTabEnum?.RTERMINAL_RTS_T2 ? <Billing businessName={formValues.businessName?.value} id={getClientIDByBusinessName()} showBill={showBill} /> : null}
>
        <TabWrapper>
                <Tab tabs={tabs} handleChange={handleTabChange} value={selectedTab} />
            </TabWrapper>
                <div className='container'>
                <TableHeader columns={updatedColumns} data={sorteData} exportFileName='Remediation' onRefreshClick={fetchPackagesData}
                    action={
                        <div className="header">
                        {selectedTab === RTSTabEnum?.RTERMINAL_RTS_T1 && (
                    <>
                        <TextField {...trackingIDField} />
                        {!loading && (
                        <PrimaryButton type="button" label={"Scan"} height={"20px"} onClick={handleSubmitClick} isSquare={true}/>
                        )} 
                    </>
                )}
              </div>
            }/>
                <TableFilter {...tableFilterProps}/>
                    <div className='content'>
                        <DataTable columns={columns} data={packages || []} isCollpsable={false} showPagination={packages?.length ? true : false} onPageChange={handlePageChange} page={page} totalRowsCount={packageCount} onSortChange={setSortedData} uniqueKey={'packageDetailsId'} setUpdatedColumns={setUpdatedColumns} handleChangeRowsPerPage={handleChangeRowsPerPage} rowsPerPage={rowsPerPage} />
                    </div>
                </div>
            <Audit />
            {modalProps ? <ResponseModal {...modalProps} handleClose={handleModelClose} /> : null}
            {loading ? <Loader isLoading={loading} /> : null}
            {showAddressUpdateModal ? <EditPackageAddress isModelOpen={showAddressUpdateModal} handleClose={handlePackageEditCick} selectedPackage={selectedPackage} /> : null}
            {showStatusUpdateModal ? <RescheduleDelivery isModelOpen={showStatusUpdateModal} handleClose={handlePackageStatusClick} allowedStatuses={rtsAllowedStatuses} /> : null}
        </Layout>
    )
}