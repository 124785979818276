import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Radio, RadioGroup, FormControlLabel, FormControl } from '@mui/material';
import { getRoute, getRouteHistoricData } from 'features/route/routeAction'
import './routeDetailsComponent.scss'
import { InductionParcel } from './inductionParcel'
import { getRouteParcels } from 'utils/routeHelper'
import './routeDetailsComponent.scss'

const HEIGHT = '24rem'

const options = [
    { key: 'allParcels', value: 'allParcels', label: 'All Parcels' },
    { key: 'historicData', value: 'historicData', label: 'Historic Data' }
]

export const InductionRouteDetails = React.memo(({ id }) => {
    const dispatch = useDispatch();
    const { data: routeData, historicData } = useSelector(
        (state) => state.route
    )
    const { user } = useSelector(
        (state) => state?.auth
    )

    const [selectedOption, handleOptionChange] = useState('allParcels')
    const routeDetail = selectedOption === 'historicData' ? historicData[id] : routeData[id]

    useEffect(() => {
        dispatch(selectedOption === 'historicData' ? getRouteHistoricData(id) : getRoute(id))
    }, [selectedOption])

    //Convert datain format which helper function accepts, as it is common helper function
    const data = selectedOption === 'historicData' ? getRouteParcels([{ 'packageDetails': routeDetail }], user) : getRouteParcels(routeDetail?.routeStops, user)

    return (
        <>
            <FormControl component="fieldset" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <RadioGroup
                    row
                    aria-label="options"
                    name="options"
                    value={selectedOption}
                    onChange={(event) => handleOptionChange(event.target.value)}
                >
                    {options.map((option) => (
                        <FormControlLabel
                            key={option.key}
                            value={option.value}
                            control={<Radio />}
                            label={<span className="text">{option.label}</span>}
                        />
                    ))}
                </RadioGroup>
            </FormControl>
            <InductionParcel data={data} height={HEIGHT} selectedOption={selectedOption}/>
        </>
    )
})