import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from "react-router-dom";
import DataTable from 'component/dataTable/dataTable'
import { ResponseModal } from 'component/common/Modal/ResponseModal'
import { Loader } from 'component/common/Loader/Loader'
import { fetchRoutes, rejectRoute, fetchRouteSummary } from 'features/route/routeAction'
import { RouteDetails } from './routeDetailsComponent'
import { Layout } from 'component/common/Layout/Layout';
import { TableHeader } from 'component/dataTable/tableHeader';
import { TableFilter } from 'component/dataTable/tableFilter';
import 'component/userManagement/userAccount/UserAccount.scss'
import { getFSAZones, convertDateRangeToUTC, getUserDCOption, iconStyle, getConstantValue, getDateWithTimeStamp, getCurrentDateForFilter, checkPermission, getGlobalFilter } from 'utils/common'
import { validateForm, validateStartAndEndDate } from 'utils/formValidator'
import DeleteIcon from '@mui/icons-material/Delete';
import { resetSuccessState } from 'features/route/routeSlice'
import { ConfirmationModal } from 'component/common/Modal/ConfirmationModal'
import { routeStatus, routeStatusToNotAllowedToReject } from 'constants/routeStatus'
import InfoIcon from '@mui/icons-material/Info';
import { setAuditData } from 'features/audit/auditSlice'
import { Audit } from 'component/audit/audit'
import { allowDutyAssign } from 'utils/dailyRoasterHelper'
import { AssignDriver } from './assignDriverModal'
import { setPackageFilter } from 'features/packages/packagesSlice'
import { setToteFilter } from 'features/warehouseOperation/warehouseOperationSlice'
import { Link, Tooltip } from '@mui/material';
import { DeliveryStatusFilter } from 'component/packages/DeliveryStatusFilter'
import { DeliveryStatusCellRenderer } from 'component/packages/deliveryStatusCellRenderer'
import { RescheduleRoute } from 'component/route/RescheduleRouteModal'
import DriverCellRenderer from './assignDriverCellRenderer'
// import SupportIcon from '@mui/icons-material/Support';
import { resetError } from 'features/route/routeSlice'
import { resetSuccessData } from 'features/dailyRoaster/dailyRoasterSlice'
import LoopIcon from '@mui/icons-material/Loop';
import { EditRoute } from './EditRoute'
import { urls } from 'utils/urls'
import { canEditMissingTotes, handleExportRouteClick, handleExportToPDFClick, getRouteSummary } from 'utils/routeHelper'
import useFieldChange from 'hooks/useFieldChange';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'; // Import the export icon
import PrintIcon from '@mui/icons-material/Print';
import { AccordianComponent } from 'component/common/Accordian/Accordian'
import { TerminateRouteModal } from './TerminateRouteModal';
import CancelIcon from '@mui/icons-material/Cancel';
import {  getDeliveryStatusValue } from 'utils/packageHelper'

const date = getCurrentDateForFilter()
const formInitialState = {
    fsaZoneNames: '',
    routeStatus: '',
    routeName: '',
    routeId:'',
    startDate: date,
    endDate: date,
}

export const Routes = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [page, setPage] = useState(0);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const routeId = queryParams.get('routeId');

    const { routes, loading, error, routeCount, routeSuccess, successMessage, rescheduleRouteSuccess, routeSummmary, terminateRouteSuccess } = useSelector(
        (state) => state?.route
    )
    const { error: dailyRosterError } = useSelector(
        (state) => state?.dailyRoaster
    )
    const { isMobile } = useSelector(
        (state) => state?.viewport
    )
    const { user } = useSelector(
        (state) => state?.auth
    )
    const { updateRoasterSuccess } = useSelector(
        (state) => state?.dailyRoaster
    )
    const { sla, highPriority } = useSelector(
        (state) => state?.deliveryStatusManagement
    )
    const { fsaZones } = useSelector(
        (state) => state?.fsaZones
    )
    const [modalProps, setModalProps] = useState(null)
    const [formValues, setFormValues, handleFieldChange] = useFieldChange(formInitialState);
    const [applyFilter, setApplyFilter] = useState(false);
    const [formError, setFormErrors] = useState({});
    const [sorteData, setSortedData] = useState(routes);
    const [updatedColumns, setUpdatedColumns] = useState([]);
    const [confirmationModalProps, setConfirmationModalProps] = useState(null);
    const [selectedRoute, setSelectedRoute] = useState(null)
    const [assignDriverModalOpen, setAssignDriverModalOpen] = useState(null)
    const [rescheduleRouteModalOpen, setRescheduleRouteModalOpen] = useState(false)
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [showEditRouteModal, setShowEditRouteModal] = useState(false)
    const [isRouteTerminateModal, setIsRouteTerminateModal] = useState(false)

    const rowKey = "dailyRouteScanSummaryVM.routeId"
    const hasEditPermission = checkPermission(user, 'ROUTE')

    const fetchData = () => {
        const globalFilter = getGlobalFilter(highPriority, sla)
        const { startDate, endDate } = formValues
        const dateFilter = convertDateRangeToUTC(startDate, endDate, "YYYY-MM-DD")

        const data = {}
        if (formValues?.routeName) {
            data['routeName'] = formValues?.routeName?.trim() || null
        }
        if (formValues?.fsaZoneNames?.label) {
            data['fsaZoneNames'] = formValues?.fsaZoneNames?.label?.trim() || null
        }
        if (formValues?.routeStatus?.value) {
            data['routeStatus'] = formValues?.routeStatus?.value?.trim() || null
        }
        if(formValues?.routeId){
            data['routeId'] = formValues?.routeId?.trim() || null
        }
        data['widget'] = 'ROUTES'
        dispatch(fetchRoutes({
            page: page,
            size: rowsPerPage,
            ...data,
            ...globalFilter,
            ...dateFilter,
            dcName: getUserDCOption(user)?.value
        }))
        dispatch(fetchRouteSummary({
            widget: 'ROUTES',
            ...dateFilter,
            ...globalFilter,
            dcName: getUserDCOption(user)?.value
        }))
    }
    

    useEffect(() => {
        if (!routeId) {
            fetchData()
        }
    }, [page, applyFilter, routeId, rowsPerPage, highPriority, sla])

    useEffect(() => {
        if (updateRoasterSuccess) {
            if (routeId) {
                dispatch(fetchRoutes({ routeId: routeId, fetchRouteDetail: true }))
            }
            else {
                fetchData()
            }
        }
    }, [updateRoasterSuccess])

    useEffect(() => {
        if (routeId) {
            dispatch(fetchRoutes({ routeId: routeId, fetchRouteDetail: true }))
        }
    }, [routeId])

    useEffect(() => {
        if (routeSuccess || rescheduleRouteSuccess || terminateRouteSuccess) {
            setModalProps(
                {
                    title: 'Success',
                    message: successMessage ? successMessage : 'Route update successfully done!',
                    open: true,
                    type: 'success'
                }
            )
            const redirectTimer = setTimeout(() => {
                setConfirmationModalProps(null)
                setModalProps(null)
                setShowEditRouteModal(false)
                setIsRouteTerminateModal(false)
                if (rescheduleRouteSuccess) {
                    setRescheduleRouteModalOpen()
                }
                dispatch(resetSuccessState())
                fetchData()
            }, 1000);
            return () => {
                clearTimeout(redirectTimer);
            }
        }
    }, [routeSuccess, rescheduleRouteSuccess, terminateRouteSuccess]);

    useEffect(() => {
        return () => {
            // This code will run when the component unmounts
            dispatch(resetError())
            dispatch(resetSuccessData())
        };
    }, [])

    useEffect(() => {
        if (error || dailyRosterError) {
            setModalProps(
                {
                    title: 'Error Occured!',
                    message: error || dailyRosterError,
                    open: true,
                    type: 'error'
                }
            )

        }
    }, [error || dailyRosterError]);

    useEffect(() => {
        //If user is on last page and deleted all records from that page, then page should be reset to page-1
        const pageCount = Math.ceil(routeCount / 10)
        if (routeCount && (page > 1) && (pageCount < page)) {
            setPage(page - 1)
        }
    }, [routeCount])

    const handlePageChange = (event, page) => {
        setPage(page)
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event?.target?.value, 10));
        setPage(0);
    };

    const handleResetFilter = () => {
        setFormValues({
            fsaZoneNames: '',
            routeStatus: '',
            routeName: '',
            startDate: '',
            endDate: '',
            routeId: ''
        }
        )
    }

    const handleRejectRouteClick = (routeId) => {
        if (routeId) {
            setConfirmationModalProps(
                {
                    title: 'Reject Route',
                    message: 'Are you sure, you want to reject this route?',
                    open: true,
                    onConfirm: handleRejectRoute,
                    selectedId: routeId,
                    onClose: handleConfirmationClose
                }
            )
        }
    }

    const handleConfirmationClose = () => {
        setConfirmationModalProps(null)
    }

    const handleRejectRoute = (routeId) => {
        dispatch(rejectRoute(routeId))
    }

    // const handleRescueRouteClick = (routeId) => {
    //     dispatch(rescueRoute(routeId))
    // }

    const handleRescheduleRouteClick = (route = null) => {
        setSelectedRoute(route)
        setRescheduleRouteModalOpen(!rescheduleRouteModalOpen)
    }

    const handleApplyFilterClick = () => {
        const notRequiredFields = ['routeId', 'startDate', 'endDate', 'routeName', 'fsaZoneNames', 'routeStatus']
        const errors = validateForm(formValues, notRequiredFields);
        const dateErrors = validateStartAndEndDate(formValues?.startDate, formValues?.endDate);
        if (Object.keys(errors).length || Object.keys(dateErrors).length) {
            setFormErrors(Object.keys(errors).length ? errors : dateErrors)
        }
        else {
            setFormErrors({})
            setApplyFilter(!applyFilter)
            setPage(0)
        }
    }

    const handleAssignDriverModalClick = (route = null) => {
        setSelectedRoute(route)
        setAssignDriverModalOpen(!assignDriverModalOpen)
    }

    const handleNavigation = (params) => {
        const { showTote, ...rest } = params
        if (showTote) {
            dispatch(setToteFilter(rest))
            navigate(urls?.TOTES_WIDGET_URL)
        }
        else {
            dispatch(setPackageFilter(rest))
            navigate(urls?.PARCEL_WIDGET_URL)
        }
    }

    const handleRouteEditCick = (data) => {
        if (!showEditRouteModal) {
            setSelectedRoute(data)
            setShowEditRouteModal(true)
        }
        else {
            setSelectedRoute(null)
            setShowEditRouteModal(false)
        }
    }

    const handleTerminateRouteClick = (route) => {
        setSelectedRoute(route)
        setIsRouteTerminateModal(!isRouteTerminateModal)
    }

    const handleRouteTerminate = () => {
        setIsRouteTerminateModal(!isRouteTerminateModal)
    }

    //Column props should be json, so that any extra property can be added
    const columns = [{
        accessor: "dailyRouteScanSummaryVM.routeId",
        title: "Route ID",
        width: "2rem",
        Cell: ({ cell: { row: { original } } }) => original?.dailyRouteScanSummaryVM?.routeId || '--',
        render: (item) => item?.dailyRouteScanSummaryVM?.routeId || '--'
    },
    {
        accessor: "dailyRouteScanSummaryVM.routeName",
        title: "Route Name",
        width: "4rem",
        Cell: ({ cell: { row: { original } } }) => original?.dailyRouteScanSummaryVM?.routeName || '--',
        render: (item) => item?.dailyRouteScanSummaryVM?.routeName || '--'
    },
    {
        accessor: "dailyRouteScanSummaryVM.dailyRouteStatus",
        title: "Route Status",
        width: "4rem",
        Cell: ({ cell: { row: { original } } }) => original?.dailyRouteScanSummaryVM?.dailyRouteStatus ? getConstantValue(routeStatus, original?.dailyRouteScanSummaryVM?.dailyRouteStatus) : '--',
        render: (item) => item?.dailyRouteScanSummaryVM?.dailyRouteStatus ? getConstantValue(routeStatus, item?.dailyRouteScanSummaryVM?.dailyRouteStatus) : '--'
    },
    {
        accessor: 'deliveryStatus',
        title: "Route Attributes",
        width: "5rem",
        Cell: ({ cell: { row: { original } } }) => <DeliveryStatusCellRenderer key={original?.dailyRouteScanSummaryVM?.routeId} isHighPriority={original?.highPriorityCount} isSLA={original?.dailyRouteScanSummaryVM?.routeSLA}/>,
        render: (item) => getDeliveryStatusValue(item?.highPriorityCount, item?.dailyRouteScanSummaryVM?.routeSLA),
        isSortable: false
    },
    {
        accessor: 'dailyRouteScanSummaryVM.totalTotes',
        title: "Total Totes",
        width: "2rem",
        Cell: ({ cell: { row: { original } } }) => original?.dailyRouteScanSummaryVM?.totalTotes ? <Link id='dailyRouteId' onClick={() => handleNavigation({
            routeId: original?.dailyRouteScanSummaryVM?.routeId,
            showTote: true
        })} style={iconStyle} sx={{ textDecoration: 'none' }}>{original?.dailyRouteScanSummaryVM?.totalTotes}</Link > : '--',
        render: (item) => item?.dailyRouteScanSummaryVM?.totalTotes || '--'
    },
    {
        accessor: 'totalPackages',
        title: "Total Parcels",
        width: "2rem",
        Cell: ({ cell: { row: { original } } }) => original?.totalPackages ? <Link id='routetId' style={{ cursor: 'pointer' }} sx={{ textDecoration: 'none' }} onClick={() => handleNavigation({
            routeId: original?.dailyRouteScanSummaryVM?.routeId
        })}>{original?.totalPackages}</Link> : original?.totalPackages,
        render: (item) => item?.totalPackages,
    },
    {
        accessor: 'totalStops',
        title: "Total Stops",
        width: "2rem",
        Cell: ({ cell: { value } }) => value,
        render: (item) => item?.totalStops
    },
    {
        accessor: 'driverId',
        title: "Driver ID",
        width: "2rem",
        Cell: ({ cell: { value } }) => value,
        render: (item) => item?.driverId
    },
    {
        accessor: 'driverName',
        title: "Driver Name",
        width: "3rem",
        Cell: ({ cell: { row: { original } } }) => allowDutyAssign(original?.dailyRouteScanSummaryVM?.dailyRouteStatus) ? <DriverCellRenderer key={original?.dailyRouteScanSummaryVM?.routeId} route={original} /> : original?.driverName ? original?.driverName : '--',
        render: (item) => item?.driverName || '--'
    },
    {
        accessor: 'primaryFsaZone',
        title: "Primary FSA Zone",
        width: "3rem",
        Cell: ({ cell: { row: { original } } }) => original?.dailyRouteScanSummaryVM?.primaryFsaZone || '--',
        render: (item) => item?.dailyRouteScanSummaryVM?.primaryFsaZone || '--'
    },
    {
        accessor: 'distributionCenterResponseVm.dcName',
        title: "DC Name",
        width: "3rem",
        Cell: ({ cell: { row: { original } } }) => original?.distributionCenterResponseVm?.dcName || '--',
        render: (item) => item?.distributionCenterResponseVm?.dcName || '--'
    },
    {
        accessor: "dailyRouteScanSummaryVM.routeStartDate",
        title: "Route Start Date",
        width: "4rem",
        Cell: ({ cell: { row: { original } } }) => original?.dailyRouteScanSummaryVM?.routeStartDate ? getDateWithTimeStamp(original?.dailyRouteScanSummaryVM?.routeStartDate, user) : '--',
        render: (item) => item?.dailyRouteScanSummaryVM?.routeStartDate ? getDateWithTimeStamp(item?.dailyRouteScanSummaryVM?.routeStartDate, user) : '--'
    },
    {
        accessor: 'dailyRouteScanSummaryVM.createDate',
        title: "Created At",
        width: "3rem",
        Cell: ({ cell: { row: { original } } }) => original?.dailyRouteScanSummaryVM?.createDate ? getDateWithTimeStamp(original?.dailyRouteScanSummaryVM?.createDate, user) : '--',
        render: (item) => item?.dailyRouteScanSummaryVM?.createDate ? getDateWithTimeStamp(item?.dailyRouteScanSummaryVM?.createDate, user) : '--'
    },
    hasEditPermission && {
        accessor: 'action',
        title: "Action",
        isSortable: false,
        width: "2rem",
        Cell: ({ cell: { row: { original } } }) => <div className='action'>
            {canEditMissingTotes(original) ? <Tooltip title="Edit"><img src="static/images/edit-icon.svg" alt='edit' onClick={() => handleRouteEditCick(original)} /></Tooltip> : null}
            {
                !routeStatusToNotAllowedToReject?.includes(original?.dailyRouteScanSummaryVM?.dailyRouteStatus) ?
                    <Tooltip title="Reject">
                        <DeleteIcon style={iconStyle} fontSize="small" onClick={() => handleRejectRouteClick(original?.dailyRouteScanSummaryVM?.routeId)} />
                    </Tooltip>
                    : null}
            <Tooltip title="Export Route"> <CloudDownloadIcon style={iconStyle} fontSize="small" onClick={() => handleExportRouteClick(original, user)} />
            </Tooltip>
            <Tooltip title="Print Route"> <PrintIcon style={iconStyle} fontSize="small" onClick={() => handleExportToPDFClick(original, user)} />
            </Tooltip>


            {/* {
                sosRoutes.includes(original?.dailyRouteScanSummaryVM?.dailyRouteStatus) ?
                    <Tooltip title="Rescue">
                        <SupportIcon style={iconStyle} fontSize="small" onClick={() => handleRescueRouteClick(original?.dailyRouteScanSummaryVM?.routeId)} />
                    </Tooltip>
                    : null} */}
            {['ROUTE_CREATED']?.includes(original?.dailyRouteScanSummaryVM?.dailyRouteStatus) ?
                <Tooltip title="Reschedule"><LoopIcon style={iconStyle} fontSize="small" onClick={() => handleRescheduleRouteClick(original?.dailyRouteScanSummaryVM?.routeId)} /></Tooltip> : null
            }
            {['ROUTE_STARTED']?.includes(original?.dailyRouteScanSummaryVM?.dailyRouteStatus) ?
                <Tooltip title="Terminate Route"><CancelIcon style={iconStyle} fontSize="small" onClick={() => handleTerminateRouteClick(original?.dailyRouteScanSummaryVM?.routeId)} /></Tooltip> : null
            }
            <Tooltip title="Info"><InfoIcon style={iconStyle} fontSize="small" onClick={() => dispatch(setAuditData({ 'entityId': original?.dailyRouteScanSummaryVM?.routeId, 'entityType': 'daily-routes' }))} /></Tooltip>
        </div>
    }
    ]?.filter(Boolean)


    const tableFilterProps = {
        fields: [
            {
                label: 'Route ID',
                value: formValues?.routeId,
                handleFieldChange: handleFieldChange,
                type: 'text',
                width: isMobile ? '9rem' : '15rem',
                name: 'routeId',
                placeholder:"Route ID"
            },
            {
                label: 'Route Name',
                value: formValues?.routeName,
                handleFieldChange: handleFieldChange,
                type: 'text',
                width: isMobile ? '9rem' : '15rem',
                name: 'routeName',
                placeholder: 'Route Name'
            },
            {
                label: 'Route Status',
                value: formValues?.routeStatus,
                handleFieldChange: handleFieldChange,
                type: 'select',
                width: isMobile ? '9rem' : '15rem',
                name: 'routeStatus',
                options: routeStatus,
            },
            {
                label: 'FSA Zone',
                value: formValues?.fsaZoneNames,
                handleFieldChange: handleFieldChange,
                type: 'select',
                width: isMobile ? '9rem' : '15rem',
                options: getFSAZones(fsaZones),
                name: 'fsaZoneNames'
            },
            {
                label: 'Start Date',
                value: formValues?.startDate,
                handleFieldChange: handleFieldChange,
                type: 'date',
                width: isMobile ? '9rem' : '13rem',
                name: 'startDate',
                placeholder: 'DD-MM-YYYY',
                error: formError?.startDate,
            },
            {
                label: 'End Date',
                value: formValues?.endDate,
                handleFieldChange: handleFieldChange,
                type: 'date',
                width: isMobile ? '9rem' : '13rem',
                name: 'endDate',
                placeholder: 'DD-MM-YYYY',
                error: formError?.endDate,
                min: formValues?.startDate,
            }],
        buttons: [
            {
                label: 'Apply',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'black',
                onClick: handleApplyFilterClick
            },
            {
                label: 'Reset',
                variant: 'contained',
                color: 'white',
                backgroundColor: 'blue',
                onClick: handleResetFilter
            }
        ],
        margin: '0px',
        padding: '10px',
        borderRadius: '10px'
    }

    return (
        <Layout headerTitle={routeId ? 'Route' : 'Routes'} globalFilter={!routeId ? <DeliveryStatusFilter /> : null} showBackArrow={false}>
            <AccordianComponent data={getRouteSummary(routeSummmary, 'ROUTES')} isMobile={isMobile}/>
            <div className='container'>
                {!routeId ? <TableHeader columns={updatedColumns} data={sorteData} exportFileName='Routes' onRefreshClick={handleApplyFilterClick}  /> : null}
                {!routeId ? <TableFilter {...tableFilterProps} /> : null}
                <div className='content'>
                    {routeId ?
                        <div className='route-details'>
                            <DataTable columns={columns} data={[routes]} showPagination={false} rowKey={rowKey} childComponent={RouteDetails} isCollpsable={true} uniqueKey={'dailyRouteScanSummaryVM.routeId'} />
                        </div>
                        :
                        <DataTable columns={columns} data={routes?.length ? routes : []} showPagination={routes?.length ? true : false} rowKey={rowKey} childComponent={RouteDetails} isCollpsable={true} page={page} totalRowsCount={routeCount} onPageChange={handlePageChange} onSortChange={setSortedData} uniqueKey={'dailyRouteScanSummaryVM.routeId'} setUpdatedColumns={setUpdatedColumns} handleChangeRowsPerPage={handleChangeRowsPerPage} rowsPerPage={rowsPerPage} />
                    }
                </div>
            </div>
            {modalProps ? <ResponseModal {...modalProps} handleClose={() => setModalProps(null)} /> : null}
            {loading ? <Loader isLoading={loading} /> : null}
            {confirmationModalProps ? <ConfirmationModal {...confirmationModalProps} /> : null}
            <Audit />
            {assignDriverModalOpen ? <AssignDriver isModelOpen={assignDriverModalOpen} handleClose={handleAssignDriverModalClick} route={selectedRoute} /> : null}
            {rescheduleRouteModalOpen ? <RescheduleRoute isModelOpen={rescheduleRouteModalOpen} handleClose={handleRescheduleRouteClick} route={selectedRoute} user={user} tabName={'Reschedule_Route'} /> : null}
            {showEditRouteModal ? <EditRoute isModelOpen={showEditRouteModal} handleClose={handleRouteEditCick} route={selectedRoute} /> : null}
            {isRouteTerminateModal ? <TerminateRouteModal isModelOpen={isRouteTerminateModal} handleClose={handleRouteTerminate} route = {selectedRoute}/> : null} 
        </Layout>
    )
}